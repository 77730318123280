import { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';

const MouseTracker = ({leftTurretRef, rightTurretRef, mousePosRef }) => {
  const { camera, gl } = useThree();

  useEffect(() => {
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    const targetPlaneOffset = camera.position.y;
    const targetPlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), -targetPlaneOffset);

    const handleMouseMove = (event) => {
      const canvasBounds = gl.domElement.getBoundingClientRect();
      mouse.x = ((event.clientX - canvasBounds.left) / canvasBounds.width) * 2 - 1;
      mouse.y = -((event.clientY - canvasBounds.top) / canvasBounds.height) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const targetPoint = new THREE.Vector3();
      raycaster.ray.intersectPlane(targetPlane, targetPoint);

      if (targetPoint) {

        if (mousePosRef.current) {
          mousePosRef.current.set(targetPoint.x, targetPoint.y, targetPoint.z);
        }
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [camera, gl, leftTurretRef, rightTurretRef, mousePosRef]);

  return null;
};

export default MouseTracker;
