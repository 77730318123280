import React, { useRef, useMemo, useEffect } from 'react';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';

const GalaxyBackground = () => {
  const galaxyRef = useRef();
  const { scene } = useThree();
  const starCount = 2000;
  const galaxyRadius = 70;
  const rotationSpeed = 0.0003;
  const outerColour = useMemo(() => new THREE.Color(0x506277), []);

  useEffect(() => {
    if (galaxyRef.current) {
      galaxyRef.current.rotation.x = -0.75; 
      galaxyRef.current.rotation.y = 1;
      galaxyRef.current.position.z = -500;
      galaxyRef.current.position.x = -400;
      galaxyRef.current.position.y = 500;
    }
    
    const light = new THREE.PointLight(outerColour, 1, galaxyRadius * 2);
    light.position.set(-400, 500, -500);
    scene.add(light);
  
    return () => {
      scene.remove(light);
    };
  }, [scene, outerColour]);
  

  const starsMaterial = useMemo(() => new THREE.PointsMaterial({
    size: 0.1,
    sizeAttenuation: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    vertexColors: true,
    transparent: true
  }), []);

  const starsGeometry = useMemo(() => {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];
  
    const innerColor = new THREE.Color(0x506277); 
    const middleColor = new THREE.Color(0x84818c);
    const outerColor = new THREE.Color(0x506277); 
  
    for (let i = 0; i < starCount; i++) {
      const radius = galaxyRadius * Math.sqrt(Math.random());
      const branches = 2 + Math.random() * 4;
      const branchAngle = (2 * Math.PI) / branches;
      const angle = branchAngle * (i % branches);
  
      const x = radius * Math.cos(angle);
      const y = radius * Math.sin(angle);
      const z = (Math.random() - 0.5) * 2;
  
      positions.push(x, y, z);
  
      const distance = Math.sqrt(x * x + y * y + z * z);
      const color = new THREE.Color();
  
      if (distance < galaxyRadius * 0.3) {
        color.lerpColors(innerColor, middleColor, distance / (galaxyRadius * 0.3));
      } else if (distance < galaxyRadius * 0.8) {
        color.lerpColors(middleColor, outerColor, (distance - galaxyRadius * 0.3) / (galaxyRadius * 0.4));
      } else {
        color.copy(outerColor);
      }
  
      colors.push(color.r, color.g, color.b);
    }
  
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
  
    return geometry;
  }, [starCount, galaxyRadius]);
  

  useFrame(() => {
    if (galaxyRef.current) {
      galaxyRef.current.rotation.z += rotationSpeed;
    }
  });

  return <points ref={galaxyRef} geometry={starsGeometry} material={starsMaterial} />;
};

export default GalaxyBackground;
