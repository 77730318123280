import React from 'react';
import Button from '@mui/material/Button';

const OverlayCard = () => {
  return (
    <div style={{
        position: 'absolute',
        top: '10%',
        left: '50%',
        transform: 'translate(-50%, 0)',
        width: '40%',
        padding: '20px',
        backgroundColor: 'rgba(255, 255, 255, 0)', 
        zIndex: 1000,
        textAlign: 'center',
        color: 'grey'
      }}>
      <h1>Hi, I'm Daniel Ye</h1>
      <p>Welcome to my website :)</p>
      <a href="/Daniel_Ye_Resume.pdf" style={{ textDecoration: 'none' }}>
        <Button variant="contained" style={{ margin: '10px', backgroundColor: 'transparent', color: 'grey', fontSize: '12px', padding: '8px' }}>Resume</Button>
      </a>
      <a href="https://www.linkedin.com/in/danielyedaniel/" style={{ textDecoration: 'none' }}>
        <Button variant="contained" style={{ margin: '10px', backgroundColor: 'transparent', color: 'grey', fontSize: '12px', padding: '8px' }}>LinkedIn</Button>
      </a>
      <a href="https://github.com/danielyedaniel" style={{ textDecoration: 'none' }}>
        <Button variant="contained" style={{ margin: '10px', backgroundColor: 'transparent', color: 'grey', fontSize: '12px', padding: '8px' }}>GitHub</Button>
      </a>
      <a href="/blackjack" style={{ textDecoration: 'none' }}>
        <Button variant="contained" style={{ margin: '10px', backgroundColor: 'transparent', color: 'grey', fontSize: '12px', padding: '8px' }}>Balance Sheet</Button>
      </a>
    </div>
  );
};

export default OverlayCard;
