import React, { useState, useRef, useCallback, forwardRef } from 'react';
import { Canvas } from '@react-three/fiber';
import Projectile from '../three/Projectile';
import Turret from '../three/Turret';
import MouseTracker from '../three/MouseTracker';
import { Stars } from '@react-three/drei';
import StarStreak from '../three/StarStreak';
import GalaxyBackground from '../three/GalaxyBackground';
import CustomStars from '../three/CustomStars';
// import SpaceRocks from './three/SpaceRocks';
import OverlayCard from '../generics/OverLayCard';
import * as THREE from 'three';

const HomePage = () => {
  const [projectiles, setProjectiles] = useState([]);
  const leftTurretRef = useRef();
  const rightTurretRef = useRef();
  const mousePosRef = useRef(new THREE.Vector3(0, 0, 0));

  const calculateProjectileStartPosition = useCallback((turretRef) => {

    const tipOffset = new THREE.Vector3(0, 2.25, 0); 
    const tipWorldPosition = tipOffset.applyQuaternion(turretRef.quaternion).add(turretRef.position);
    return tipWorldPosition.toArray();
  }, []);

  const shoot = useCallback((event) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const boxWidth = screenWidth * 0.7;
    const boxHeight = screenHeight * 0.7;
    const boxLeft = (screenWidth - boxWidth) / 2;
    const boxRight = boxLeft + boxWidth;
    const boxTop = (screenHeight - boxHeight) / 2;
    const boxBottom = boxTop + boxHeight;
  
    const { clientX, clientY } = event;
  
    if (
      clientX >= boxLeft && clientX <= boxRight &&
      clientY >= boxTop && clientY <= boxBottom
    ) {
      [leftTurretRef, rightTurretRef].forEach((ref) => {
        if (!ref.current) return;
  
        // const offset = ref === leftTurretRef ? new THREE.Vector3(-7.8, -1, -0.09) : new THREE.Vector3(7.8, -1, -0.09);
        const offset = ref === leftTurretRef ? new THREE.Vector3(-7.8, -0.5, -0.09) : new THREE.Vector3(7.8, -0.5, -0.09);

        const targetPosition = mousePosRef.current.clone().add(offset);
        const directionVec = targetPosition.sub(ref.current.position).normalize();
  
        const startPosition = calculateProjectileStartPosition(ref.current);
        const velocity = [directionVec.x * 0.75, directionVec.y * 0.75, directionVec.z * 30];
  
        setProjectiles((prev) => [
          ...prev,
          { id: Math.random(), position: startPosition, velocity },
        ]);
      });
    }
  }, [calculateProjectileStartPosition]);
  

  const removeProjectile = useCallback((id) => {
    setProjectiles((prev) => prev.filter((p) => p.id !== id));
  }, []);

  const Tur = forwardRef(({ position }, ref) => (
    <Turret ref={ref} position={position}>
    </Turret>
  ));

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      {/*change cam position z setting back to 3 for turrets*/}
      <Canvas onClick={shoot} camera={{ position: [0, -1, 2], fov: 75 }}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <MouseTracker
          leftTurretRef={leftTurretRef}
          rightTurretRef={rightTurretRef}
          mousePosRef={mousePosRef}
        />

        {/*Turrent implementation will come later*/}
        <Tur ref={leftTurretRef} position={[-8, -2, -1]} />
        <Tur ref={rightTurretRef} position={[8, -2, -1]} />
        {projectiles.map(({ id, position, velocity }) => (
          <Projectile key={id} position={position} velocity={velocity} onDestroy={() => removeProjectile(id)} />
        ))}

        <StarStreak count={3} />
        {/* <SpaceRocks count={3} /> */}
        <CustomStars />
        <Stars count={100} speed={0.75}/>
        <Stars count={75} speed={1.5}/>
        <GalaxyBackground />
      </Canvas>
      <OverlayCard/>
    </div>
  );
};

export default HomePage;
