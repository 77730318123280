import React, { forwardRef, useMemo, useEffect } from 'react';
import * as THREE from 'three';

const Turret = forwardRef(({ position }, ref) => {

  const slotsPositions = useMemo(() => {
    const positions = [];
    const numberOfSlots = 6; 
    const radius = 0.3;
    for (let i = 0; i < numberOfSlots; i++) {
      const angle = (i / numberOfSlots) * Math.PI * 2;
      positions.push(new THREE.Vector3(Math.cos(angle) * radius, 0, Math.sin(angle) * radius));
    }
    return positions;
  }, []);

  useEffect(() => {
    if (ref.current) {
      const directionVector = new THREE.Vector3(0, 1.7, 1);
      ref.current.lookAt(directionVector.add(ref.current.position));
    }
  }, [position, ref]);


  return (
    <group ref={ref} position={position}>
      {/* Main barrel */}
      <mesh>
        <cylinderGeometry args={[0.3, 0.3, 4.5, 32]} />
        <meshStandardMaterial color={'gray'} />
      </mesh>
      {/* Slots around the barrel */}
      {slotsPositions.map((slotPosition, index) => (
        <mesh key={index} position={slotPosition}>
          <cylinderGeometry args={[0.05, 0.05, 4, 32]} />
          <meshStandardMaterial color={'black'} />
        </mesh>
      ))}
    </group>
  );
});

export default Turret;
