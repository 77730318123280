import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const Projectile = ({ position, velocity, onDestroy }) => {
  const ref = useRef();
  const materialRef = useRef(new THREE.MeshPhongMaterial({
    color: new THREE.Color("#1D75AB"),
    emissive: new THREE.Color("#0000FF"),
    emissiveIntensity: 1,
  }));
  
  const creationTimeRef = useRef(0);
  useFrame((state) => {
    if (!creationTimeRef.current) {
      creationTimeRef.current = state.clock.elapsedTime;
    }

    if (ref.current) {
      ref.current.position.x += velocity[0];
      ref.current.position.y += velocity[1];
      ref.current.position.z += velocity[2];
      
      if (Math.abs(ref.current.position.z) > 70) {
        onDestroy();
      }

      const elapsedTime = state.clock.elapsedTime - creationTimeRef.current;
      const pulse = Math.sin(elapsedTime * 10) * 3 + 2;
      const colorIntensity = (pulse + 1) / 2;
      materialRef.current.emissiveIntensity = colorIntensity;

      const velocityVector = new THREE.Vector3(...velocity);
      velocityVector.normalize();
      ref.current.lookAt(velocityVector.add(ref.current.position));
      ref.current.rotateX(Math.PI / 2);
    }
  });

  return (
    <mesh ref={ref} position={position} material={materialRef.current}>
      <cylinderGeometry args={[0.1, 0.1, 0.8, 32]} />
    </mesh>
  );
};

export default Projectile;
