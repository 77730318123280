import React, { useRef, useMemo, useCallback } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

const StarStreak = ({ count = 100 }) => {
  const { viewport, camera } = useThree();
  const meshRef = useRef();
  const dummy = useMemo(() => new THREE.Object3D(), []);

  const getPositionInView = useCallback((depth) => {
    const aspect = viewport.aspect;
    const vFov = THREE.MathUtils.degToRad(camera.fov);
    const height = 2 * Math.tan(vFov / 2) * depth;
    const width = height * aspect;

    return new THREE.Vector3(
      (Math.random() - 0.5) * width,
      (Math.random() - 0.5) * height,
      camera.position.z - depth
    );
  }, [viewport, camera]);

  const material = useMemo(() => new THREE.MeshBasicMaterial({ color: 'white' }), []);

  const [positions, velocities] = useMemo(() => {
    const pos = new Array(count);
    const vel = new Array(count);
    for (let i = 0; i < count; i++) {
      pos[i] = getPositionInView(200 + Math.random() * 100);
      vel[i] = -1 - Math.random();
    }
    return [pos, vel];
  }, [count, getPositionInView]);

  useFrame(() => {
    positions.forEach((position, i) => {
      position.z -= velocities[i];

      if (position.z > camera.position.z) {
        position.copy(getPositionInView(50 + Math.random() * 50));
      }

      dummy.position.copy(position);
      const direction = new THREE.Vector3(0, 0, 1).add(position).sub(camera.position);
      dummy.lookAt(direction);
      dummy.rotation.x = Math.PI / 2;
      dummy.updateMatrix();
      meshRef.current.setMatrixAt(i, dummy.matrix);
    });

    meshRef.current.instanceMatrix.needsUpdate = true;
  });

  return (
    <instancedMesh ref={meshRef} args={[null, material, count]}>
      <cylinderGeometry args={[0.02, 0.02, 1, 32]} />
    </instancedMesh>
  );
};

export default StarStreak;
